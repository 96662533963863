@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
}

#app {
  min-height: 100vh; /* Assurer que l'application prend toute la hauteur de l'écran */
  display: flex;
  flex-direction: column;
}

.navbar,
.footer {
  overflow: hidden;
  width: 100%; /* S'assurer que ces éléments ne débordent pas */
}

.main-content {
  flex-grow: 1; /* Permet au contenu principal d'occuper tout l'espace restant */
}
